/*

=========================================================
* Now UI Kit React - v1.5.2
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2023 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/main/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"

// styles for this kit
import "assets/css/bootstrap.min.css"
import "assets/scss/now-ui-kit.scss?v=1.5.0"
import "assets/demo/demo.css?v=1.5.0"
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0"
// pages for this kit
import Index from "views/Index.js"
import NucleoIcons from "views/NucleoIcons.js"
import LoginPage from "views/examples/LoginPage.js"
import LandingPage from "views/examples/LandingPage.js"
import ProfilePage from "views/examples/ProfilePage.js"
import HomePage from "views/examples/HomePage.js"

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/index" element={<ProfilePage />} />
            <Route path="/example" element={<Index />} />
            <Route path="/nucleo-icons" element={<NucleoIcons />} />
            <Route path="/landing-page" element={<LandingPage />} />
            <Route path="/profile-page" element={<ProfilePage />} />
            <Route path="/login-page" element={<LoginPage />} />

            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    </BrowserRouter>
)
