import React from "react"

// reactstrap components
import {
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
} from "reactstrap"

// core components

function Navbars() {
    const [collapseOpen, setCollapseOpen] = React.useState(false)
    return (
        <>
            <div className="section section-navbars">
                <Container id="menu-dropdown">
                    <Row>
                        <Col md="6">
                            <h4>Menu</h4>
                            <Navbar className="bg-primary" expand="lg">
                                <Container>
                                    <NavbarBrand
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        Menu
                                    </NavbarBrand>
                                    <button
                                        onClick={() => {
                                            document.documentElement.classList.toggle(
                                                "nav-open"
                                            )
                                            setCollapseOpen(!collapseOpen)
                                        }}
                                        aria-expanded={collapseOpen}
                                        className="navbar-toggler"
                                    >
                                        <span className="navbar-toggler-bar bar1"></span>
                                        <span className="navbar-toggler-bar bar2"></span>
                                        <span className="navbar-toggler-bar bar3"></span>
                                    </button>
                                    <Collapse isOpen={collapseOpen} navbar>
                                        <Nav navbar>
                                            <NavItem className="active">
                                                <NavLink
                                                    href="#pablo"
                                                    onClick={(e) =>
                                                        e.preventDefault()
                                                    }
                                                >
                                                    <p>Link</p>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    href="#pablo"
                                                    onClick={(e) =>
                                                        e.preventDefault()
                                                    }
                                                >
                                                    <p>Link</p>
                                                </NavLink>
                                            </NavItem>
                                            <UncontrolledDropdown nav>
                                                <DropdownToggle
                                                    aria-haspopup={true}
                                                    caret
                                                    color="default"
                                                    href="http://example.com?ref=creativetim"
                                                    nav
                                                >
                                                    <p>Dropdown</p>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem
                                                        href="#pablo"
                                                        onClick={(e) =>
                                                            e.preventDefault()
                                                        }
                                                    >
                                                        My Profile
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        href="#pablo"
                                                        onClick={(e) =>
                                                            e.preventDefault()
                                                        }
                                                    >
                                                        My Books
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        href="#pablo"
                                                        onClick={(e) =>
                                                            e.preventDefault()
                                                        }
                                                    >
                                                        Something else here
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </Nav>
                                    </Collapse>
                                </Container>
                            </Navbar>
                        </Col>
                        <Col md="6">
                            <h4>Menu with Icons</h4>
                            <Navbar className="bg-info" expand="lg">
                                <Container>
                                    <NavbarBrand
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        Icons
                                    </NavbarBrand>
                                    <button
                                        onClick={() => {
                                            document.documentElement.classList.toggle(
                                                "nav-open"
                                            )
                                            setCollapseOpen(!collapseOpen)
                                        }}
                                        aria-expanded={collapseOpen}
                                        className="navbar-toggler"
                                        type="button"
                                    >
                                        <span className="navbar-toggler-bar bar1"></span>
                                        <span className="navbar-toggler-bar bar2"></span>
                                        <span className="navbar-toggler-bar bar3"></span>
                                    </button>
                                    <Collapse isOpen={collapseOpen} navbar>
                                        <Nav className="ml-auto" navbar>
                                            <NavItem>
                                                <NavLink
                                                    href="#pablo"
                                                    onClick={(e) =>
                                                        e.preventDefault()
                                                    }
                                                >
                                                    <i
                                                        aria-hidden={true}
                                                        className="now-ui-icons ui-1_send"
                                                    ></i>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    href="#pablo"
                                                    onClick={(e) =>
                                                        e.preventDefault()
                                                    }
                                                >
                                                    <i
                                                        aria-hidden={true}
                                                        className="now-ui-icons users_single-02"
                                                    ></i>
                                                </NavLink>
                                            </NavItem>
                                            <UncontrolledDropdown nav>
                                                <DropdownToggle
                                                    caret
                                                    color="default"
                                                    href="#pablo"
                                                    nav
                                                    onClick={(e) =>
                                                        e.preventDefault()
                                                    }
                                                >
                                                    <i
                                                        aria-hidden={true}
                                                        className="now-ui-icons ui-1_settings-gear-63"
                                                    ></i>
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <DropdownItem
                                                        header
                                                        tag="a"
                                                    >
                                                        Dropdown header
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        href="#pablo"
                                                        onClick={(e) =>
                                                            e.preventDefault()
                                                        }
                                                    >
                                                        Action
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        href="#pablo"
                                                        onClick={(e) =>
                                                            e.preventDefault()
                                                        }
                                                    >
                                                        Another action
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        href="#pablo"
                                                        onClick={(e) =>
                                                            e.preventDefault()
                                                        }
                                                    >
                                                        Something else here
                                                    </DropdownItem>
                                                    <div className="divider"></div>
                                                    <DropdownItem
                                                        href="#pablo"
                                                        onClick={(e) =>
                                                            e.preventDefault()
                                                        }
                                                    >
                                                        Soccer programme
                                                    </DropdownItem>
                                                    <div className="divider"></div>
                                                    <DropdownItem
                                                        href="#contactme"
                                                        onClick={(e) =>
                                                            e.preventDefault()
                                                        }
                                                    >
                                                        Contact me
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </Nav>
                                    </Collapse>
                                </Container>
                            </Navbar>
                        </Col>
                    </Row>
                    <h4>Navigation</h4>
                </Container>
                <div id="navbar">
                    <div
                        className="navigation-example"
                        style={{
                            backgroundImage:
                                "url(" + require("assets/img/bg7.jpg") + ")",
                        }}
                    >
                        <Navbar className="bg-primary" expand="lg">
                            <Container>
                                <div className="navbar-translate">
                                    <NavbarBrand
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        Primary color
                                    </NavbarBrand>
                                    <button
                                        onClick={() => {
                                            document.documentElement.classList.toggle(
                                                "nav-open"
                                            )
                                            setCollapseOpen(!collapseOpen)
                                        }}
                                        aria-expanded={collapseOpen}
                                        className="navbar-toggler"
                                        type="button"
                                    >
                                        <span className="navbar-toggler-bar bar1"></span>
                                        <span className="navbar-toggler-bar bar2"></span>
                                        <span className="navbar-toggler-bar bar3"></span>
                                    </button>
                                </div>
                                <Collapse isOpen={collapseOpen} navbar>
                                    <Nav className="ml-auto" navbar>
                                        <NavItem className="active">
                                            <NavLink
                                                href="#pablo"
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <i className="now-ui-icons objects_globe"></i>
                                                <p>Discover</p>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#pablo"
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <i className="now-ui-icons users_circle-08"></i>
                                                <p>Profile</p>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#pablo"
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <i className="now-ui-icons ui-1_settings-gear-63"></i>
                                                <p>Settings</p>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </Collapse>
                            </Container>
                        </Navbar>
                        <Navbar className="bg-info" expand="lg">
                            <Container>
                                <div className="navbar-translate">
                                    <NavbarBrand
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        Info Color
                                    </NavbarBrand>
                                    <button
                                        onClick={() => {
                                            document.documentElement.classList.toggle(
                                                "nav-open"
                                            )
                                            setCollapseOpen(!collapseOpen)
                                        }}
                                        aria-expanded={collapseOpen}
                                        className="navbar-toggler"
                                        type="button"
                                    >
                                        <span className="navbar-toggler-bar bar1"></span>
                                        <span className="navbar-toggler-bar bar2"></span>
                                        <span className="navbar-toggler-bar bar3"></span>
                                    </button>
                                </div>
                                <Collapse isOpen={collapseOpen} navbar>
                                    <Nav className="ml-auto" navbar>
                                        <NavItem className="active">
                                            <NavLink
                                                href="#pablo"
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <p>Discover</p>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#pablo"
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <p>Profile</p>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#pablo"
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <p>Settings</p>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </Collapse>
                            </Container>
                        </Navbar>
                        <Navbar className="bg-success" expand="lg">
                            <Container>
                                <div className="navbar-translate">
                                    <NavbarBrand
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        Success Color
                                    </NavbarBrand>
                                    <button
                                        onClick={() => {
                                            document.documentElement.classList.toggle(
                                                "nav-open"
                                            )
                                            setCollapseOpen(!collapseOpen)
                                        }}
                                        aria-expanded={collapseOpen}
                                        className="navbar-toggler"
                                        type="button"
                                    >
                                        <span className="navbar-toggler-bar bar1"></span>
                                        <span className="navbar-toggler-bar bar2"></span>
                                        <span className="navbar-toggler-bar bar3"></span>
                                    </button>
                                </div>
                                <Collapse isOpen={collapseOpen} navbar>
                                    <Nav className="ml-auto" navbar>
                                        <NavItem className="active">
                                            <NavLink
                                                href="#pablo"
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <i className="now-ui-icons objects_globe"></i>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#pablo"
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <i className="now-ui-icons users_circle-08"></i>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#pablo"
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <i className="now-ui-icons ui-1_settings-gear-63"></i>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </Collapse>
                            </Container>
                        </Navbar>
                        <Navbar className="bg-warning" expand="lg">
                            <Container>
                                <div className="navbar-translate">
                                    <NavbarBrand
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        Warning Color
                                    </NavbarBrand>
                                    <button
                                        onClick={() => {
                                            document.documentElement.classList.toggle(
                                                "nav-open"
                                            )
                                            setCollapseOpen(!collapseOpen)
                                        }}
                                        aria-expanded={collapseOpen}
                                        className="navbar-toggler"
                                        type="button"
                                    >
                                        <span className="navbar-toggler-bar bar1"></span>
                                        <span className="navbar-toggler-bar bar2"></span>
                                        <span className="navbar-toggler-bar bar3"></span>
                                    </button>
                                </div>
                                <Collapse isOpen={collapseOpen} navbar>
                                    <Nav className="ml-auto" navbar>
                                        <NavItem>
                                            <NavLink
                                                href="#pablo"
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <i className="fab fa-facebook-square"></i>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#pablo"
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <i className="fab fa-twitter"></i>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#pablo"
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <i className="fab fa-google-plus"></i>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#pablo"
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <i className="fab fa-instagram"></i>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </Collapse>
                            </Container>
                        </Navbar>
                        <Navbar className="bg-danger" expand="lg">
                            <Container>
                                <div className="navbar-translate">
                                    <NavbarBrand
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        Danger Color
                                    </NavbarBrand>
                                    <button
                                        onClick={() => {
                                            document.documentElement.classList.toggle(
                                                "nav-open"
                                            )
                                            setCollapseOpen(!collapseOpen)
                                        }}
                                        aria-expanded={collapseOpen}
                                        className="navbar-toggler"
                                        type="button"
                                    >
                                        <span className="navbar-toggler-bar bar1"></span>
                                        <span className="navbar-toggler-bar bar2"></span>
                                        <span className="navbar-toggler-bar bar3"></span>
                                    </button>
                                </div>
                                <Collapse isOpen={collapseOpen} navbar>
                                    <Nav className="ml-auto" navbar>
                                        <NavItem>
                                            <NavLink
                                                href="#pablo"
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <i className="fab fa-facebook-square"></i>
                                                <p>Share</p>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#pablo"
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <i className="fab fa-twitter"></i>
                                                <p>Tweet</p>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#pablo"
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <i className="fab fa-pinterest"></i>
                                                <p>Pin</p>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </Collapse>
                            </Container>
                        </Navbar>
                        <Navbar className="navbar-transparent" expand="lg">
                            <Container>
                                <div className="navbar-translate">
                                    <NavbarBrand
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        Transparent
                                    </NavbarBrand>
                                    <button
                                        onClick={() => {
                                            document.documentElement.classList.toggle(
                                                "nav-open"
                                            )
                                            setCollapseOpen(!collapseOpen)
                                        }}
                                        aria-expanded={collapseOpen}
                                        className="navbar-toggler"
                                        type="button"
                                    >
                                        <span className="navbar-toggler-bar bar1"></span>
                                        <span className="navbar-toggler-bar bar2"></span>
                                        <span className="navbar-toggler-bar bar3"></span>
                                    </button>
                                </div>
                                <Collapse isOpen={collapseOpen} navbar>
                                    <Nav className="ml-auto" navbar>
                                        <NavItem>
                                            <NavLink
                                                href="#pablo"
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <i className="fab fa-facebook-square"></i>
                                                <p>Facebook</p>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#pablo"
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <i className="fab fa-twitter"></i>
                                                <p>Twitter</p>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#pablo"
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <i className="fab fa-instagram"></i>
                                                <p>Instagram</p>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </Collapse>
                            </Container>
                        </Navbar>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbars
