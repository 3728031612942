import React from "react"

// reactstrap components
import {
    Button,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
} from "reactstrap"

import CarouselSection from "../index-sections/Carousel.js"
import ContentTabs from "../index-sections/ContentTabs.js"

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js"
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js"
import DefaultFooter from "components/Footers/DefaultFooter.js"

function ProfilePage() {
    const [pills, setPills] = React.useState("2")
    React.useEffect(() => {
        document.body.classList.add("profile-page")
        document.body.classList.add("sidebar-collapse")
        document.documentElement.classList.remove("nav-open")
        window.scrollTo(0, 0)
        document.body.scrollTop = 0
        return function cleanup() {
            document.body.classList.remove("profile-page")
            document.body.classList.remove("sidebar-collapse")
        }
    }, [])
    return (
        <>
            <ExamplesNavbar />
            <div className="wrapper">
                <ProfilePageHeader />
                <div className="section">
                    <Container>
                        <div className="button-container">
                            <Button
                                className="btn-round"
                                color="info"
                                size="lg"
                            >
                                Follow
                            </Button>
                            <Button
                                className="btn-round btn-icon"
                                color="default"
                                id="tooltip515203352"
                                size="lg"
                            >
                                <i className="fab fa-twitter"></i>
                            </Button>
                            <UncontrolledTooltip
                                delay={0}
                                target="tooltip515203352"
                            >
                                Follow me on Twitter
                            </UncontrolledTooltip>
                            <Button
                                className="btn-round btn-icon"
                                color="default"
                                id="tooltip340339231"
                                size="lg"
                            >
                                <i className="fab fa-instagram"></i>
                            </Button>
                            <UncontrolledTooltip
                                delay={0}
                                target="tooltip340339231"
                            >
                                Follow me on Instagram
                            </UncontrolledTooltip>
                        </div>
                        <h3 className="title">About Garfield...</h3>
                        <h5 className="description">
                            Garfield Robinson holds a B.A. in Theology from the
                            Jamaica Theological Seminary. He is a Bible teacher
                            and discipleship leader at Portmore Gospel Assembly,
                            a ministry committed to worship, fellowship,
                            edification, evangelization, and spiritual warfare,
                            for the purpose of reconciling and discipling the
                            nations unto Christlikeness. Garfield is married to
                            Marlene and he has three daughters.
                        </h5>
                        <CarouselSection />
                        <Row>
                            <Col className="ml-auto mr-auto" md="6">
                                <h4 className="title text-center">
                                    My Portfolio
                                </h4>
                                <div className="nav-align-center">
                                    <Nav
                                        className="nav-pills-info nav-pills-just-icons"
                                        pills
                                        role="tablist"
                                    >
                                        <NavItem>
                                            <NavLink
                                                className={
                                                    pills === "1"
                                                        ? "active"
                                                        : ""
                                                }
                                                href="#pablo"
                                                title="My Ministry"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setPills("1")
                                                }}
                                            >
                                                <i className="now-ui-icons travel_info"></i>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={
                                                    pills === "2"
                                                        ? "active"
                                                        : ""
                                                }
                                                href="#pablo"
                                                title="My Books"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setPills("2")
                                                }}
                                            >
                                                <i className="now-ui-icons files_single-copy-04"></i>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={
                                                    pills === "3"
                                                        ? "active"
                                                        : ""
                                                }
                                                href="#pablo"
                                                title="My Messages"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setPills("3")
                                                }}
                                            >
                                                <i className="now-ui-icons media-1_button-play"></i>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                            </Col>
                            <TabContent
                                className="gallery"
                                activeTab={"pills" + pills}
                            >
                                <TabPane tabId="pills1">
                                    <Col className="ml-auto mr-auto" md="10">
                                        <Row className="collections">
                                            <Col md="6">
                                                <a
                                                    href="https://youtu.be/uY4l22iQhsE?t=362"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Wednesday Worship on CVM TV"
                                                >
                                                    <img
                                                        alt="You Chube"
                                                        className="img-raised"
                                                        src={require("assets/img/bg1.jpg")}
                                                    ></img>
                                                </a>
                                                <img
                                                    alt="..."
                                                    className="img-raised"
                                                    src={require("assets/img/bg3.jpg")}
                                                ></img>
                                            </Col>
                                            <Col md="6">
                                                <img
                                                    alt="..."
                                                    className="img-raised"
                                                    src={require("assets/img/bg8.jpg")}
                                                ></img>
                                                <img
                                                    alt="..."
                                                    className="img-raised"
                                                    src={require("assets/img/bg7.jpg")}
                                                ></img>
                                            </Col>
                                        </Row>
                                    </Col>
                                </TabPane>
                                <TabPane tabId="pills2">
                                    <Col className="ml-auto mr-auto" md="10">
                                        <Row className="collections">
                                            <Col md="6">
                                                <img
                                                    alt="..."
                                                    className="img-raised"
                                                    src={require("assets/img/bg6.jpg")}
                                                ></img>
                                                <img
                                                    alt="..."
                                                    className="img-raised"
                                                    src={require("assets/img/bg11.jpg")}
                                                ></img>
                                            </Col>
                                            <Col md="6">
                                                <img
                                                    alt="..."
                                                    className="img-raised"
                                                    src={require("assets/img/bg7.jpg")}
                                                ></img>
                                                <img
                                                    alt="..."
                                                    className="img-raised"
                                                    src={require("assets/img/bg8.jpg")}
                                                ></img>
                                            </Col>
                                        </Row>
                                    </Col>
                                </TabPane>
                                <TabPane tabId="pills3">
                                    <Col className="ml-auto mr-auto" md="10">
                                        <Row className="collections">
                                            <Col md="6">
                                                <img
                                                    alt="..."
                                                    className="img-raised"
                                                    src={require("assets/img/bg3.jpg")}
                                                ></img>
                                                <img
                                                    alt="..."
                                                    className="img-raised"
                                                    src={require("assets/img/bg8.jpg")}
                                                ></img>
                                            </Col>
                                            <Col md="6">
                                                <img
                                                    alt="..."
                                                    className="img-raised"
                                                    src={require("assets/img/bg7.jpg")}
                                                ></img>
                                                <img
                                                    alt="..."
                                                    className="img-raised"
                                                    src={require("assets/img/bg6.jpg")}
                                                ></img>
                                            </Col>
                                        </Row>
                                    </Col>
                                </TabPane>
                            </TabContent>
                            <ContentTabs />
                        </Row>
                    </Container>
                </div>
                <DefaultFooter />
            </div>
        </>
    )
}

export default ProfilePage
