/*eslint-disable*/
import React from "react"

// reactstrap components
import { Container } from "reactstrap"

// core components

function DefaultFooter() {
    return (
        <>
            <footer className="footer footer-default">
                <Container>
                    <nav>
                        <ul>
                            <li>
                                <a
                                    href="https://forms.gle/L5nkunyJsWcGga9M6"
                                    target="_blank"
                                >
                                    Reach out to me
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div className="copyright" id="copyright">
                        © {new Date().getFullYear()}, Developed by{" "}
                        <a href="https://twitter.com/odsamuels" target="_blank">
                            Nointid Works
                        </a>
                        .
                    </div>
                </Container>
            </footer>
        </>
    )
}

export default DefaultFooter
