import React from "react"
import { Link } from "react-router-dom"
// reactstrap components
import {
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    UncontrolledTooltip,
} from "reactstrap"

function ExamplesNavbar() {
    const [navbarColor, setNavbarColor] = React.useState("navbar-transparent")
    const [collapseOpen, setCollapseOpen] = React.useState(false)
    React.useEffect(() => {
        const updateNavbarColor = () => {
            if (
                document.documentElement.scrollTop > 399 ||
                document.body.scrollTop > 399
            ) {
                setNavbarColor("")
            } else if (
                document.documentElement.scrollTop < 400 ||
                document.body.scrollTop < 400
            ) {
                setNavbarColor("navbar-transparent")
            }
        }
        window.addEventListener("scroll", updateNavbarColor)
        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor)
        }
    })
    return (
        <>
            {collapseOpen ? (
                <div
                    id="bodyClick"
                    onClick={() => {
                        document.documentElement.classList.toggle("nav-open")
                        setCollapseOpen(false)
                    }}
                />
            ) : null}
            <Navbar
                className={"fixed-top " + navbarColor}
                color="info"
                expand="lg"
            >
                <Container>
                    <UncontrolledDropdown className="button-dropdown">
                        <DropdownToggle
                            caret
                            data-toggle="dropdown"
                            href="#pablo"
                            id="navbarDropdown"
                            tag="a"
                            onClick={(e) => e.preventDefault()}
                        >
                            <span className="button-bar"></span>
                            <span className="button-bar"></span>
                            <span className="button-bar"></span>
                        </DropdownToggle>
                        <DropdownMenu aria-labelledby="navbarDropdown">
                            <DropdownItem header tag="a">
                                Dropdown header
                            </DropdownItem>
                            <DropdownItem href="/">Home</DropdownItem>
                            <DropdownItem divider></DropdownItem>
                            <DropdownItem
                                href="https://www.amazon.com/s?i=digital-text&rh=p_27%3AGarfield+Robinson&s=relevancerank&text=Garfield+Robinson&ref=dp_byline_sr_ebooks_1"
                                target="_blank"
                            >
                                Books on Amazon
                            </DropdownItem>
                            <DropdownItem divider></DropdownItem>
                            <DropdownItem
                                href="https://forms.gle/L5nkunyJsWcGga9M6"
                                target="_blank"
                            >
                                Contact me
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <div className="navbar-translate">
                        <NavbarBrand href="#" id="navbar-brand">
                            Menu
                        </NavbarBrand>
                        <UncontrolledTooltip target="#navbar-brand">
                            Scroll down for more
                        </UncontrolledTooltip>
                        <button
                            className="navbar-toggler navbar-toggler"
                            onClick={() => {
                                document.documentElement.classList.toggle(
                                    "nav-open"
                                )
                                setCollapseOpen(!collapseOpen)
                            }}
                            aria-expanded={collapseOpen}
                            type="button"
                        >
                            <span className="navbar-toggler-bar top-bar"></span>
                            <span className="navbar-toggler-bar middle-bar"></span>
                            <span className="navbar-toggler-bar bottom-bar"></span>
                        </button>
                    </div>
                    <Collapse
                        className="justify-content-end"
                        isOpen={collapseOpen}
                        navbar
                    >
                        <Nav navbar>
                            <NavItem>
                                <NavLink to="/" tag={Link}>
                                    Home
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="https://forms.gle/L5nkunyJsWcGga9M6"
                                    target="_blank"
                                >
                                    <i className="now-ui-icons ui-1_email-85"></i>
                                    <p>Get in touch</p>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default ExamplesNavbar
