import React from "react"

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Container,
    Row,
    Col,
    Button,
} from "reactstrap"

// core components

function ContentTabs() {
    // const [iconPills, setIconPills] = React.useState("1")
    const [pills, setPills] = React.useState("1")
    return (
        <>
            <div className="section" id="pablo">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="10" xl="12">
                            <Card>
                                <CardHeader>
                                    <Nav
                                        className="nav-tabs-neutral justify-content-center"
                                        data-background-color="blue"
                                        role="tablist"
                                        tabs
                                    >
                                        <NavItem>
                                            <NavLink
                                                className={
                                                    pills === "1"
                                                        ? "active"
                                                        : ""
                                                }
                                                href="#books"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setPills("1")
                                                }}
                                            >
                                                Books
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={
                                                    pills === "2"
                                                        ? "active"
                                                        : ""
                                                }
                                                href="#engagements"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setPills("2")
                                                }}
                                            >
                                                Engagements
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={
                                                    pills === "3"
                                                        ? "active"
                                                        : ""
                                                }
                                                href="#sports"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setPills("3")
                                                }}
                                            >
                                                Sports
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={
                                                    pills === "4"
                                                        ? "active"
                                                        : ""
                                                }
                                                href="#contactme"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setPills("4")
                                                }}
                                            >
                                                Contact me
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardHeader>
                                <CardBody>
                                    <TabContent
                                        className="text-left"
                                        activeTab={"pills" + pills}
                                    >
                                        <TabPane tabId="pills1">
                                            <Row className="mb-4">
                                                <Col
                                                    md="4"
                                                    className="text-center"
                                                >
                                                    <a
                                                        href="https://www.amazon.com/Dare-Worship-That-Goes-Beyond-ebook/dp/B0C7N8PS63/ref=sr_1_1?dib=eyJ2IjoiMSJ9.q8bxuFRMcHX0meRs27d-gAp3Un1ljBo2qbBXhhmUW09BO_Azv5jqr31HUOWJ3UTX.UM6hy31OoqHHNuNrtz3oxVcxK4vOdAsw8hZQddwbx14&dib_tag=se&qid=1715977966&refinements=p_27%3AGarfield+Robinson&s=digital-text&sr=1-1&text=Garfield+Robinson"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        title="Dare To Worship Book on Amazon"
                                                        id="book1"
                                                    >
                                                        <img
                                                            src={require("assets/img/book-dare_to_worship.jpg")}
                                                            alt="Dare To Worship"
                                                        ></img>
                                                    </a>
                                                </Col>
                                                <Col md="6" className="medium">
                                                    <p>
                                                        <h4>
                                                            Worship that Goes
                                                            Beyond the Art
                                                        </h4>
                                                        If worship means a lot
                                                        to you, this book will
                                                        too. It covers the topic
                                                        of worship in a complete
                                                        and concise way. He
                                                        explains what authentic
                                                        worship is and what to
                                                        avoid as we come to
                                                        worship. He addresses
                                                        worship that goes beyond
                                                        the art.
                                                    </p>
                                                    <p className="text-center">
                                                        <a
                                                            href="https://www.amazon.com/Dare-Worship-That-Goes-Beyond-ebook/dp/B0C7N8PS63/ref=sr_1_1?dib=eyJ2IjoiMSJ9.q8bxuFRMcHX0meRs27d-gAp3Un1ljBo2qbBXhhmUW09BO_Azv5jqr31HUOWJ3UTX.UM6hy31OoqHHNuNrtz3oxVcxK4vOdAsw8hZQddwbx14&dib_tag=se&qid=1715977966&refinements=p_27%3AGarfield+Robinson&s=digital-text&sr=1-1&text=Garfield+Robinson"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            title="Dare To Worship Book on Amazon"
                                                        >
                                                            <Button
                                                                color="warning"
                                                                type="button"
                                                            >
                                                                Buy on Amazon
                                                            </Button>
                                                        </a>
                                                    </p>
                                                </Col>
                                            </Row>
                                            <hr></hr>
                                            <Row className="mb-4">
                                                <Col
                                                    md="4"
                                                    className="text-center text-bottom"
                                                >
                                                    <a
                                                        href="https://www.amazon.com/Dare-Contend-Defense-Doctrine-Salvation-ebook/dp/B0B2HVPCWP/ref=sr_1_2?dib=eyJ2IjoiMSJ9.q8bxuFRMcHX0meRs27d-gAp3Un1ljBo2qbBXhhmUW09BO_Azv5jqr31HUOWJ3UTX.UM6hy31OoqHHNuNrtz3oxVcxK4vOdAsw8hZQddwbx14&dib_tag=se&qid=1715977966&refinements=p_27%3AGarfield+Robinson&s=digital-text&sr=1-2&text=Garfield+Robinson"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        title="Dare To Contend Book on Amazon"
                                                    >
                                                        <img
                                                            src={require("assets/img/book-dare_to_contend.jpg")}
                                                            alt="Dare To Contend"
                                                        ></img>
                                                    </a>
                                                </Col>
                                                <Col md="6" className="medium">
                                                    <p>
                                                        <h4>
                                                            Contending for the
                                                            Faith
                                                        </h4>
                                                        Ever wondered what terms
                                                        like Salvation,
                                                        Sanctification and
                                                        Redemption really mean?
                                                        Dare to Contend
                                                        addresses these and
                                                        other topics related to
                                                        the doctrine of
                                                        Salvation in a concise
                                                        way without leaving out
                                                        any of its rich
                                                        contents. In this new
                                                        work, Garfield explains
                                                        the doctrine of
                                                        Salvation by dealing
                                                        with all of its main
                                                        components. Take the
                                                        journey with the author.
                                                    </p>
                                                    <p className="text-center">
                                                        <a
                                                            href="https://www.amazon.com/Dare-Contend-Defense-Doctrine-Salvation-ebook/dp/B0B2HVPCWP/ref=sr_1_2?dib=eyJ2IjoiMSJ9.q8bxuFRMcHX0meRs27d-gAp3Un1ljBo2qbBXhhmUW09BO_Azv5jqr31HUOWJ3UTX.UM6hy31OoqHHNuNrtz3oxVcxK4vOdAsw8hZQddwbx14&dib_tag=se&qid=1715977966&refinements=p_27%3AGarfield+Robinson&s=digital-text&sr=1-2&text=Garfield+Robinson"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            title="Dare To Contend Book on Amazon"
                                                        >
                                                            <Button
                                                                color="warning"
                                                                type="button"
                                                            >
                                                                Buy on Amazon
                                                            </Button>
                                                        </a>
                                                    </p>
                                                </Col>
                                            </Row>
                                            <hr></hr>
                                            <Row className="mb-4">
                                                <Col
                                                    md="4"
                                                    className="text-center text-bottom"
                                                >
                                                    <a
                                                        href="https://www.amazon.com/Dare-Ask-Questions-Scripture-Matter-ebook/dp/B095RCKHN6/ref=sr_1_3?dib=eyJ2IjoiMSJ9.q8bxuFRMcHX0meRs27d-gAp3Un1ljBo2qbBXhhmUW09BO_Azv5jqr31HUOWJ3UTX.UM6hy31OoqHHNuNrtz3oxVcxK4vOdAsw8hZQddwbx14&dib_tag=se&qid=1715977966&refinements=p_27%3AGarfield+Robinson&s=digital-text&sr=1-3&text=Garfield+Robinson"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        title="Dare To Ask Book on Amazon"
                                                    >
                                                        <img
                                                            src={require("assets/img/book-dare_to_ask.jpg")}
                                                            alt="Dare To Ask"
                                                        ></img>
                                                    </a>
                                                </Col>
                                                <Col md="6" className="medium">
                                                    <p>
                                                        <h4>
                                                            35 Questions from
                                                            Scripture that still
                                                            matter
                                                        </h4>
                                                        What if the questions
                                                        you are afraid to ask,
                                                        many biblical characters
                                                        asked them centuries
                                                        ago? Would you like to
                                                        find out what they asked
                                                        and if the answers are
                                                        relevant for you today?
                                                        In Dare to Ask, Garfield
                                                        Robinson, a writer and
                                                        Bible teacher for over
                                                        28 eight years, examines
                                                        35 questions people
                                                        asked in Scripture with
                                                        context and answers that
                                                        are still relevant
                                                        today.
                                                    </p>
                                                    <p className="text-center">
                                                        <a
                                                            href="https://www.amazon.com/Dare-Ask-Questions-Scripture-Matter-ebook/dp/B095RCKHN6/ref=sr_1_3?dib=eyJ2IjoiMSJ9.q8bxuFRMcHX0meRs27d-gAp3Un1ljBo2qbBXhhmUW09BO_Azv5jqr31HUOWJ3UTX.UM6hy31OoqHHNuNrtz3oxVcxK4vOdAsw8hZQddwbx14&dib_tag=se&qid=1715977966&refinements=p_27%3AGarfield+Robinson&s=digital-text&sr=1-3&text=Garfield+Robinson"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            title="Dare To Ask Book on Amazon"
                                                        >
                                                            <Button
                                                                color="warning"
                                                                type="button"
                                                            >
                                                                Buy on Amazon
                                                            </Button>
                                                        </a>
                                                    </p>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="pills2">
                                            <Row className="text-center">
                                                <Col
                                                    md="12"
                                                    className="embed-responsive embed-responsive-16by9"
                                                >
                                                    <iframe
                                                        width="100%"
                                                        height="100%"
                                                        src="https://www.youtube.com/embed/l2Pns2dr4nM?si=OAdHQlGSKituWXJ"
                                                        title="YouTube video player"
                                                        frameborder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                        referrerpolicy="strict-origin-when-cross-origin"
                                                        allowfullscreen
                                                    ></iframe>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <p>
                                                        <h4 className="text-center">
                                                            Special Wednesday
                                                            Worship
                                                        </h4>
                                                        I was the speaker
                                                        recently on CVM TV's
                                                        Wednesday Worship
                                                        Service we looked at a
                                                        special woman from Luke
                                                        7:36-47 and the kind of
                                                        worship she brought. She
                                                        dared to bring worship
                                                        that was sacrificial,
                                                        sincere and satisfying.
                                                        <br></br>
                                                        <br></br>
                                                        Check it out and be
                                                        inspired by her worship.
                                                    </p>
                                                </Col>
                                            </Row>
                                            <hr></hr>
                                            <Row>
                                                <Col md="12">
                                                    <p>
                                                        <h4 className="text-center">
                                                            Evangelism
                                                        </h4>
                                                        As a Bible teacher, for
                                                        almost 3 decades I make
                                                        myself available to
                                                        <a
                                                            href="https://youtu.be/ujy7HiHB6Bs?t=3118"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            title="Dare To Worship Book on Amazon"
                                                        >
                                                            {" "}
                                                            speak at churches
                                                        </a>
                                                        , conferences, and other
                                                        events. I am passionate
                                                        about the Word of God
                                                        and I love to share it
                                                        with others. I am
                                                        available to speak on a
                                                        variety of topics
                                                        including worship,
                                                        salvation, and the
                                                        Christian life.
                                                        <br></br>
                                                        <br></br>I am also
                                                        available to teach at
                                                        Bible Studies and
                                                        conduct workshops and
                                                        seminars on different
                                                        topics and series,
                                                        including those covered
                                                        in my books.
                                                        <br></br>
                                                        <br></br>
                                                    </p>
                                                    <p className="text-center">
                                                        Please feel free to{" "}
                                                        <a
                                                            href="https://forms.gle/L5nkunyJsWcGga9M6"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {" "}
                                                            contact me
                                                        </a>{" "}
                                                        for more information.
                                                    </p>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="pills3">
                                            <Row className="mb-4">
                                                <Col
                                                    md="4"
                                                    className="text-center text-bottom"
                                                >
                                                    <a
                                                        href="https://forms.gle/L5nkunyJsWcGga9M6"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        title="Robinson Football Academy"
                                                    >
                                                        <img
                                                            src={require("assets/img/robinson-football-academy.jpg")}
                                                            alt="Robinson Football Academy"
                                                        ></img>
                                                    </a>
                                                </Col>
                                                <Col md="6" className="medium">
                                                    <p>
                                                        <h4>
                                                            Sports Development
                                                        </h4>
                                                        Ministry through the
                                                        vehicle of sports is a
                                                        passion of mine. I am
                                                        the founder of the{" "}
                                                        <b>
                                                            Robinson Football
                                                            Academy
                                                        </b>
                                                        , a soccer club that is
                                                        dedicated to the
                                                        development of young
                                                        footballers.
                                                    </p>
                                                    <p>
                                                        The development of youth
                                                        through sports is a
                                                        powerful tool for
                                                        personal growth and
                                                        character building. It
                                                        provides opportunities
                                                        for physical fitness,
                                                        teamwork, discipline,
                                                        and leadership
                                                        development. By engaging
                                                        in sports, young
                                                        individuals can learn
                                                        valuable life skills and
                                                        cultivate a strong sense
                                                        of determination and
                                                        resilience.
                                                    </p>
                                                    <p className="text-center">
                                                        <a
                                                            href="https://forms.gle/L5nkunyJsWcGga9M6"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            title="Join the Group"
                                                        >
                                                            <Button
                                                                color="info"
                                                                type="button"
                                                            >
                                                                Join The Group
                                                            </Button>
                                                        </a>
                                                    </p>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="pills4">
                                            <Row
                                                className="mt-4 text-center"
                                                id="contactme"
                                            >
                                                <iframe
                                                    src="https://docs.google.com/forms/d/e/1FAIpQLSdi3t-NxQ4HQwDscJFw9ecUZNw-7hQdE7z0OMO5VU9uuzk44w/viewform?embedded=true"
                                                    width="90%"
                                                    height="1650"
                                                    frameborder="0"
                                                    marginheight="50"
                                                    marginwidth="0"
                                                    title="Contact Form"
                                                >
                                                    Loading…
                                                </iframe>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ContentTabs
