import React from "react"

// reactstrap components
import { Button, Container } from "reactstrap"

import ContentTabs from "../index-sections/ContentTabs.js"

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js"
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js"
import DefaultFooter from "components/Footers/DefaultFooter.js"

function HomePage() {
    React.useEffect(() => {
        document.body.classList.add("profile-page")
        document.body.classList.add("sidebar-collapse")
        document.documentElement.classList.remove("nav-open")
        window.scrollTo(0, 0)
        document.body.scrollTop = 0
        return function cleanup() {
            document.body.classList.remove("profile-page")
            document.body.classList.remove("sidebar-collapse")
        }
    }, [])
    return (
        <>
            <ExamplesNavbar />
            <div className="wrapper">
                <ProfilePageHeader />
                <div className="section">
                    <Container>
                        <div className="button-container">
                            <a
                                href="https://forms.gle/L5nkunyJsWcGga9M6"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Find out more"
                            >
                                <Button
                                    className="btn-round"
                                    color="info"
                                    size="lg"
                                >
                                    Find out more...
                                </Button>
                            </a>
                        </div>
                        <h3 className="title">About Garfield</h3>
                        <h5 className="description">
                            Garfield Robinson holds a B.A. in Theology from the
                            Jamaica Theological Seminary. He is a Speaker,
                            Coach, Bible teacher and discipleship leader at
                            Portmore Gospel Assembly, a ministry committed to
                            worship, fellowship, edification, evangelization,
                            and spiritual warfare, for the purpose of
                            reconciling and discipling the nations unto
                            Christlikeness. Garfield is married to Marlene and
                            he has three daughters.
                        </h5>
                        <ContentTabs />
                    </Container>
                </div>
                <DefaultFooter />
            </div>
        </>
    )
}

export default HomePage
